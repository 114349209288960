<template>
	<div class="pickup-history">
		<div v-if="!loading">
			<h3 class="mb-4">
				{{ translate('pickup_history') }}
			</h3>
			<div class="table-responsive">
				<table class="table table-hover table-striped text-nowrap">
					<thead>
						<tr>
							<th class="border-top-0">
								{{ translate('date_time') }}
							</th>
							<th class="border-top-0">
								{{ translate('notes') }}
							</th>
							<th class="border-top-0">
								{{ translate('options') }}
							</th>
						</tr>
					</thead>
					<tbody>
						<tr
							v-for="(item, index) in pickupHistory"
							:key="index">
							<td class="align-middle">
								{{ $moment(item.attributes.date.date).format(MMMDYHMS_FORMAT) }}
							</td>
							<td class="align-middle">
								{{ item.attributes.notes }}
							</td>
							<td class="align-middle">
								<b-button
									v-b-modal.pickup-history-modal
									v-b-tooltip.hover
									:title="translate('view_products')"
									class="btn mx-1 bg-primary-alt"
									@click="currentItem = item">
									<i class="fa fa-eye" />
								</b-button>
								<b-button
									v-if="isAdmin"
									v-b-tooltip.hover
									:title="translate('download_pickup_document')"
									class="btn mx-1 bg-primary-alt"
									:disabled="Object.values(loadings).includes(true)"
									@click="downloadPickupDocument(item.id)">
									<template v-if="loadings[item.id]">
										<i class="fa fa-fw fa-spinner fa-pulse" />
									</template>
									<template v-else>
										<i class="fa fa-download" />
									</template>
								</b-button>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<is-loading
			:loading-label="translate('loading')"
			:no-data-label="translate('data_not_found')"
			:loading="loading"
			:has-data="hasData" />
		<b-modal
			id="pickup-history-modal"
			ref="pickupHistoryModal"
			:title="translate('pickup_history')"
			:no-close-on-backdrop="true"
			:no-close-on-esc="true"
			:hide-footer="true"
			centered>
			<template v-if="currentItem && currentItem.attributes">
				<h5 class="mb-0">
					{{ translate('date_time') }}
				</h5>
				<p>
					{{ $moment(currentItem.attributes.date.date).format(MMMDYHMS_FORMAT) }}
				</p>
				<template v-if="currentItem.attributes.notes">
					<h5 class="mb-0">
						{{ translate('notes') }}
					</h5>
					<p>
						{{ currentItem.attributes.notes }}
					</p>
				</template>
				<div class="table-responsive">
					<table class="table table-hover table-striped text-nowrap">
						<thead>
							<tr>
								<th class="border-top-0">
									{{ translate('product') }}
								</th>
								<th class="border-top-0 text-right">
									{{ translate('qty') }}
								</th>
							</tr>
						</thead>
						<tbody>
							<tr
								v-for="product in currentItem.attributes.items"
								:key="product.id">
								<td class="align-middle">
									{{ product.product_name }}
								</td>
								<td class="align-middle text-right pr-4">
									{{ product.picked_up_qty }}
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</template>
		</b-modal>
	</div>
</template>

<script>
import Order from '@/util/Order';
import IsLoading from '@/components/Loading';
import { MMMDYHMS_FORMAT } from '@/settings/Dates';
import { PickupHistory } from '@/translations';
import { admin } from '@/settings/Roles';

export default {
	name: 'PickupHistory',
	messages: [PickupHistory],
	components: {
		IsLoading,
	},
	props: {
		orderId: {
			type: [Number, String],
			required: true,
		},
	},
	data() {
		return {
			admin,
			loading: true,
			error: null,
			pickupHistory: [],
			MMMDYHMS_FORMAT,
			currentItem: [],
			loadings: {},
		};
	},
	computed: {
		hasData() {
			return this.pickupHistory.length > 0;
		},
		isAdmin() {
			return this.admin.includes(this.$user.details().type);
		},
	},
	watch: {
		orderId() {
			this.fetchPickupHistory();
		},
	},
	created() {
		this.fetchPickupHistory();
	},
	methods: {
		async fetchPickupHistory() {
			try {
				const order = new Order();
				this.pickupHistory = await order.getPickupHistory(this.orderId);
			} catch (error) {
				this.error = this.translate('default_error_message');
			} finally {
				this.loading = false;
			}
		},
		async downloadPickupDocument(pickupHistoryId) {
			try {
				this.$set(this.loadings, pickupHistoryId, true);
				await (new Order()).printPickupDocument(pickupHistoryId);
			} catch (error) {
				// Do nothing
			} finally {
				this.$set(this.loadings, pickupHistoryId, false);
			}
		},
	},
};
</script>
