<template>
	<div class="row">
		<div class="col-12">
			<is-loading
				v-if="isLoading"
				:loading="isLoading"
				:loading-label="translate('loading')" />

			<div v-else>
				<div class="form-group">
					<div class="d-flex align-items-center">
						<switch-toggle
							v-model="isPartialPickup"
							variant="success"
							pill
							:remove-margin="true"
							:no-margin="true" />
						<label class="mb-0 ml-2">{{ translate('partial_pickup') }}</label>
					</div>
				</div>

				<div class="table-responsive">
					<table class="table table-bordered">
						<thead>
							<tr>
								<th>{{ translate('sku') }}</th>
								<th>{{ translate('product') }}</th>
								<th class="text-center">
									{{ translate('qty_ordered') }}
								</th>
								<th class="text-center">
									{{ translate('qty_picked_up') }}
								</th>
								<th class="text-center">
									{{ translate('qty_pending') }}
								</th>
								<th
									v-if="isPartialPickup"
									class="text-center">
									{{ translate('qty_to_be_picked_up') }}
								</th>
								<th
									v-if="isPartialPickup"
									class="text-center">
									{{ translate('qty_remaining') }}
								</th>
							</tr>
						</thead>
						<tbody>
							<tr
								v-for="product in products"
								:key="product.sku">
								<td>{{ product.sku }}</td>
								<td>{{ product.item }}</td>
								<td class="text-right">
									{{ product.qty }}
								</td>
								<td class="text-right">
									{{ product.picked_up }}
								</td>
								<td class="text-right">
									{{ product.pending_to_be_picked_up }}
								</td>
								<td
									v-if="isPartialPickup"
									class="text-right">
									<input
										v-model="toBePickedUp[product.sku]"
										type="number"
										class="form-control text-right"
										:placeholder="translate('enter_quantity')"
										:min="0"
										:max="product.pending_to_be_picked_up">
								</td>
								<td
									v-if="isPartialPickup"
									class="text-right"
									:class="{ 'text-danger': (product.pending_to_be_picked_up - (toBePickedUp[product.sku] || 0)) < 0 }">
									{{ product.pending_to_be_picked_up - (toBePickedUp[product.sku] || 0) }}
								</td>
							</tr>
						</tbody>
					</table>
				</div>

				<div
					v-if="isPartialPickup || isPartiallyPickedUpOrder"
					class="form-group">
					<label>
						{{ translate('notes') }}
						<span class="text-danger">*</span>
					</label>
					<textarea
						v-model="notes"
						class="form-control"
						:placeholder="translate('pickup_notes_placeholder')"
						rows="3" />
				</div>

				<div class="form-group">
					<button
						class="btn btn-secondary mr-2"
						type="button"
						@click="goBack">
						{{ translate('cancel') }}
					</button>
					<button
						class="btn btn-primary"
						type="button"
						:disabled="isSubmitDisabled || isProcessing"
						@click="preConfirm">
						<template v-if="isProcessing">
							<i class="fa fa-fw fa-spinner fa-pulse" /> {{ translate('processing') }}
						</template>
						<template v-else>
							{{ translate('proceed_with_pickup') }}
						</template>
					</button>
				</div>
			</div>
		</div>
		<div class="col-12">
			<hr>
			<pickup-history
				v-if="order"
				:order-id="order.id" />
		</div>
	</div>
</template>

<script>
import Order from '@/util/Order';
import { OrdersDetails } from '@/translations';
import Switch from '@/components/Switch';
import IsLoading from '@/components/Loading';
import { UNPROCESSABLE } from '@/settings/Errors';
import PickupHistory from '@/views/Orders/components/PickupHistory.vue';

export default {
	name: 'MarkItemsAsPickedUp',
	components: {
		PickupHistory,
		'switch-toggle': Switch,
		IsLoading,
	},
	messages: [OrdersDetails],
	data() {
		return {
			isPartialPickup: false,
			notes: '',
			toBePickedUp: {},
			orderService: new Order(),
			orderServiceSubmit: new Order(),
			isPartiallyPickedUpOrder: false,
			order: null,
			products: [],
			isLoading: true,
			isProcessing: false,
		};
	},
	computed: {
		isSubmitDisabled() {
			// Check if notes are required and empty
			if ((this.isPartialPickup || this.isPartiallyPickedUpOrder) && !this.notes.trim()) {
				return true;
			}

			if (!this.isPartialPickup) {
				return false;
			}

			// Check if no quantities are selected
			const hasSelectedQuantities = Object.values(this.toBePickedUp).some((qty) => qty > 0);
			if (!hasSelectedQuantities) {
				return true;
			}

			// Check if any quantity exceeds available quantity
			return this.products.some((product) => {
				const qtyToBePickedUp = this.toBePickedUp[product.sku] || 0;
				const availableQty = product.pending_to_be_picked_up;
				return qtyToBePickedUp > availableQty;
			});
		},
		areThereRemainingItems() {
			return this.products.some((product) => {
				const qtyToBePickedUp = this.toBePickedUp[product.sku] || 0;
				const remainingQty = product.pending_to_be_picked_up - qtyToBePickedUp;
				return remainingQty > 0;
			});
		},
		pendingItems() {
			return this.products.filter((product) => product.qty > (product.picked_up || 0));
		},
	},
	async created() {
		try {
			this.isLoading = true;
			const order = await this.orderService.getDetails(this.$route.params.order_id);
			this.order = order;

			// Validate order status
			const validStatuses = ['pick_up', 'pick_up_3pl', 'partially_picked_up', 'partially_picked_up_3pl'];
			if (!validStatuses.includes(order.attributes.order_info.status)) {
				(new this.$Alert()).toast('error', this.translate('order_cannot_be_picked_up'));
				this.goBack();
				return;
			}

			this.products = this.processProducts(order.attributes.products, order.attributes.already_picked_up || []);

			// Check if order has any partially picked up items
			this.isPartiallyPickedUpOrder = ['partially_picked_up', 'partially_picked_up_3pl'].includes(order.attributes.order_info.status);
		} catch (error) {
			if (error.status === 403) {
				await this.$router.push({ name: 'Forbidden' });
			}
			// Handle other errors (show alert, etc.)
		} finally {
			this.isLoading = false;
		}
	},
	methods: {
		processProducts(products, alreadyPickedUp) {
			const processedProducts = {};

			products.forEach((product) => {
				if (product.is_virtual) {
					return;
				}
				const isFreeProduct = product.sku.startsWith('F-');
				const normalSku = isFreeProduct ? product.sku.substring(2) : product.sku;

				if (!processedProducts[normalSku]) {
					processedProducts[normalSku] = {
						...product,
						sku: normalSku,
						qty: 0,
						picked_up: 0,
						pending_to_be_picked_up: 0,
					};
				}

				processedProducts[normalSku].qty += product.qty;
				processedProducts[normalSku].picked_up = alreadyPickedUp[normalSku] || 0;
				processedProducts[normalSku].pending_to_be_picked_up = processedProducts[normalSku].qty - processedProducts[normalSku].picked_up;
			});

			return Object.values(processedProducts);
		},
		buildTable(products) {
			return `<table border="1" cellspacing="0" cellpadding="5" style="width: 100%;">
				<thead>
					<tr>
						<th>${this.translate('product')}</th>
						<th>${this.translate('qty')}</th>
					</tr>
				</thead>
				<tbody>
				  ${products.map((product) => `<tr>
						<td style="text-align: left;">${product.item}</td>
						<td>${product.qty}</td>
					</tr>`).join('')}
				</tbody>
			</table>`;
		},
		preConfirm() {
			const title = this.translate('confirm_pickup_title');
			const label = this.translate('password');
			let items = this.buildTable(this.pendingItems.map((item) => {
				const newItem = { ...item };
				newItem.qty -= newItem.picked_up;
				return newItem;
			}));
			let orderStatusMessage = this.translate('order_will_be_completed');
			if (this.isPartialPickup && this.areThereRemainingItems) {
				orderStatusMessage = this.translate('order_will_be_partially_picked_up');
				items = this.buildTable(this.products.map((item) => {
					const newItem = { ...item };
					newItem.qty = this.toBePickedUp[newItem.sku] || 0;
					return newItem;
				}).filter((item) => item.qty > 0));
			}

			let message = this.translate('confirm_pickup_message', { items });
			message += `<br><div>${orderStatusMessage}</div><br>`;
			const options = {
				buttonText: this.translate('continue'),
				cancelButtonText: this.translate('cancel'),
				message,
			};
			(new this.$Alert().confirmationWithPassword(title, label, options).then((password) => {
				this.handleSubmit(password);
			})).catch(() => {});
		},
		async handleSubmit(password) {
			try {
				const payload = {
					is_partial_pickup: this.isPartialPickup,
					password: password.value,
				};

				if (this.isPartialPickup) {
					payload.products = this.products.reduce((products, product) => {
						products[product.sku] = this.toBePickedUp[product.sku] || 0;
						return products;
					}, {});
				}

				if (this.isPartialPickup || this.isPartiallyPickedUpOrder) {
					payload.notes = this.notes;
				}

				this.isProcessing = true;
				const alert = new this.$Alert();
				alert.loading(this.translate('loading_title'), this.translate('pickup_loading_text'), { allowEscapeKey: false, allowOutsideClick: false }).then(() => {});
				const response = await this.orderServiceSubmit.markItemsAsPickedUp(this.order.id, payload);
				const pickupHistoryId = response.response.pickup_history_id;
				await (new Order()).printPickupDocument(pickupHistoryId);
				alert.close();
				(new this.$Alert()).toast('success', this.translate('items_marked_as_picked_up'));

				// Redirect back to order details
				this.goBack();
			} catch (error) {
				const { errors } = this.orderServiceSubmit.data;
				let errorMessage = this.translate('default_error_message');
				if (UNPROCESSABLE.includes(errors.status) && typeof errors.errors.password !== 'undefined') {
					errorMessage = this.translate('incorrect_password');
				} else if (typeof errors.errors !== 'undefined') {
					// Show the first error
					[[errorMessage]] = Object.values(errors.errors);
				}
				(new this.$Alert()).toast('error', this.translate(errorMessage));
			} finally {
				this.isProcessing = false;
			}
		},
		goBack() {
			this.$router.push({
				name: 'OrdersDetails',
				params: { order_id: this.order.id },
			});
		},
	},
};
</script>

<style scoped>
.table th {
	white-space: nowrap;
}

.form-control {
	max-width: 100px;
}

textarea.form-control {
	max-width: 100%;
}

.table td {
	vertical-align: middle;
	height: 54px;
	padding: 0;
}

.table td input.form-control {
	height: 100%;
	max-width: 100%;
	border: none;
	border-radius: 0;
	padding: 0 0.75rem;
	background-color: #f8f9fa;
}

.table td input.form-control::placeholder {
	font-style: italic;
	color: #6c757d;
}

.table td input.form-control:focus {
	background-color: #fff;
	box-shadow: inset 0 0 0 2px #DF703D;
}

.table td:not(:has(input)) {
	padding: 0.75rem;
}
</style>
